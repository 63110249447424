import * as React from 'react';
import restProvider from 'ra-data-json-server';
import {
  Admin,
  Resource,
  CustomRoutes,
  defaultTheme, defaultDarkTheme,
} from 'react-admin';
import AppList from './components/apps/AppList';
import AppCreate from './components/apps/AppCreate';
import AppEdit from './components/apps/AppEdit';
import { Route } from 'react-router-dom';
import { MyLayout } from './layout/Layout';
import AccountList from './components/account/AccountList';
import AccountCreate from './components/account/AccountCreate';
import AccountEdit from './components/account/AccountEdit';
import UsersList from './components/users/UsersList';
import GroupCreate from './components/group/GroupCreate';
import GroupEdit from './components/group/GroupEdit';
import GroupList from './components/group/GroupList';
import { MomentarySending } from './components/pushNotifications/MomentarySending';
import BotMessage from './components/apps/BotMessage';
import authProvider from './AuthProvider';
import { AutomaticSending } from './components/pushNotifications/AutomaticSending';
import AppsRating from './components/apps/AppsRating';
import PushList from './components/pushes/PushList';
import { PushEdit } from './components/pushes/PushEdit';
import BotAppsList from './components/apps/BotAppsList';
import CampaignsList from './components/pushes/CampaignsList';
import { CreateCampaign } from './components/pushNotifications/CreateCampaign';
import { CampaignEdit } from './components/pushNotifications/CampaignEdit';
import AppGroupList from './components/appsGroups/AppGroupsList';
import AppGroupCreate from './components/appsGroups/AppGroupCreate';
import AppGroupEdit from './components/appsGroups/AppGroupEdit';
import InstallCalculator from './components/instalСalculator/InstallCalculator';
import Blacklist from './components/blackList/BlackList';
import BlacklistItemEdit from './components/blackList/BlacklistItemEdit';
import EmailParser from './components/emailParser/EmailParser';

export const API_URL = 'https://mirapanelapps.com/backend';
// export const API_URL = `${process.env.REACT_APP_API_URL}`;

const App = () => {
  return (
    <Admin
      layout={MyLayout}
      authProvider={authProvider}
      dataProvider={restProvider('/backend')}
      // dataProvider={restProvider(`${process.env.REACT_APP_API_URL}`)}
      theme={defaultTheme}
      darkTheme={defaultDarkTheme}
    >
      <CustomRoutes>
        <Route path='/app-rating' element={<AppsRating />} />
      </CustomRoutes>
      <CustomRoutes>
        <Route path='/email-parser' element={<EmailParser />} />
      </CustomRoutes>
      <CustomRoutes>
        <Route path='/install-calculator' element={<InstallCalculator />} />
      </CustomRoutes>
      <CustomRoutes>
        <Route path='/bot_messages' element={<BotMessage />} />
      </CustomRoutes>
      <CustomRoutes>
        <Route path='/momentSend' element={<MomentarySending />} />
      </CustomRoutes>
      <CustomRoutes>
        <Route path='/autoSend' element={<AutomaticSending />} />
      </CustomRoutes>
      <CustomRoutes>
        <Route path='/createcampaign' element={<CreateCampaign />} />
      </CustomRoutes>
      <Resource
        name='/push-service/push'
        list={PushList}
        // create={AppCreate}
        edit={PushEdit}
        // show={AppShow}
      />
      <Resource
        name='push-service/campaigns'
        list={CampaignsList}
        // create={CreateCampaign}
        create={CreateCampaign}
        edit={CampaignEdit}
        // show={AppShow}
      />
      {/*<Resource*/}
      {/*  name='getAnalytic'*/}
      {/*  list={AnalyticList}*/}
      {/*  create={}*/}
      {/*  edit={}*/}
      {/*  show={}*/}
      {/*/>*/}
      <Resource
        name='apps'
        list={AppList}
        create={AppCreate}
        edit={AppEdit}
        // show={AppShow}
      />
      <Resource
        name='botApps'
        list={BotAppsList}
        create={AppCreate}
        edit={AppEdit}
        // show={AppShow}
      />
      <Resource
        name='accounts'
        list={AccountList}
        create={AccountCreate}
        edit={AccountEdit}
      />
      <Resource
        name='appGroups'
        list={AppGroupList}
        create={AppGroupCreate}
        edit={AppGroupEdit}
      />
      <Resource
        name='users'
        list={UsersList}
      />
      <Resource
        name='groups'
        list={GroupList}
        create={GroupCreate}
        edit={GroupEdit}
      />
      <Resource
        name='blacklist'
        list={Blacklist}
        // create={GroupCreate}
        edit={BlacklistItemEdit}
      />
    </Admin>
  );
};

export default App;
