import * as React from 'react';
import { useEffect, useState } from 'react';
import { Title, useGetList } from 'react-admin';
import { Card, CardContent, TextField, Autocomplete } from '@mui/material';
import { Backdrop, CircularProgress } from '@material-ui/core';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

const EmailParser = () => {
  const [apps, setApps] = useState([]);
  const [value, setValue] = React.useState<any>(null);
  const [inputValue, setInputValue] = React.useState('');
  const [emails, setEmails] = React.useState([]);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    handleOpen();

    fetch(`${process.env.REACT_APP_API_URL}/email-parse`, {
    // fetch('https://mirapanelapps.com/backend/appRating', {
      method: 'POST',
      body: JSON.stringify({ appPackage: value.package }),
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(response => response.json())
      .then(response => {
        setEmails(response.emails);
        handleClose();
      })
      .catch(error => {
        handleClose();
      });
  };

  return (
    <Card sx={{ height: '100%' }}>
      <Title title='Apps Rating' />
      <CardContent>
        <Box display={{ xs: 'block', sm: 'flex' }}>
          <Box>
            <Autocomplete
              value={value}
              onChange={(event: any, newValue: string | null) => {
                setValue(newValue);
              }}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              id='controllable-states-demo'
              options={apps}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label='App' />}
            />
          </Box>
          <Box sx={{ mt: '15px', ml: '20px' }}>
            <Button
              onClick={handleClick}
              variant='contained'>
              Check
            </Button>
          </Box>
          <Box sx={{ zIndex: 1 }}>
            <Backdrop
              open={open}
              onClick={handleClose}
            >
              <CircularProgress color='inherit' />
            </Backdrop>
          </Box>
        </Box>
        {emails.length > 0 && (
          <Box sx={{ mt: '30px' }}>
            <div>awdawd</div>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default EmailParser;
